
import {
    Watch,
    Mixins,
    Component,
    Vue,
    InjectReactive
} from 'vue-property-decorator'
import Resize from "@/mixins/resize";
import tablePagination from "@/mixins/tablePagination";

import ETable from "@/components/ETable.vue";

@Component({
    inject: [],
    components: { ETable }
})
export default class NursePresWay extends Mixins(tablePagination, Resize) {
    queryForm = {
        presName: "",
        presType: 0,
        custom: ""
    };

    columns = [{
            label: "ID",
            prop: "id",
            width: 70,
            align: "center"
        },
        {
            label: "处方名称",
            prop: "storeName",
            minWidth: 150,
            align: "center"
        },
        {
            label: "智养类型",
            prop: "totalPrice",
            width: 120,
            align: "right"
        },
        {
            label: "适用人群",
            prop: "remainingPrice",
            width: 120,
            align: "right"
        },
        {
            label: "适用节气",
            prop: "periodNum",
            width: 100,
            align: "center"
        },
        {
            label: "时长（分钟）",
            prop: "repaymentDate",
            minWidth: 180,
            align: "center"
        },
        {
            label: "功效",
            prop: "repayment",
            minWidth: 200,
            align: "center"
        },
        {
            label: "调养周期",
            prop: "status",
            minWidth: 180,
            align: "center"
        },
        {
            label: "方法",
            prop: "status",
            minWidth: 180,
            align: "center"
        },
        {
            label: "状态",
            prop: "status",
            minWidth: 180,
            align: "center"
        },
        {
            label: "操作人",
            prop: "createdBy",
            minWidth: 150,
            align: "center"
        },
        {
            label: "最后操作时间",
            prop: "createdTime",
            minWidth: 180,
            align: "center"
        },
        {
            label: "操作",
            prop: "action",
            fixed: "right",
            width: 200,
            align: "center"
        },
    ]
    tableData = [{id:1}];

    onMounted() {
        this.windowResize();
    }

    handleSearch() {

    }

    add() {}
}
